<template>
  <div>
    <div class="container mb-5">
      <div class="sub-container pt-2" style="min-height: calc(100vh - 255px)">
        <h2 class="mt-4 mb-4 text-center"><b>Privacy Policy</b></h2>

        <div class="pb-4">
          <p>
            Selgate Healthcare and its affiliates are committed to maintaining
            the personal data privacy, confidentiality and security of your
            personally identifiable information ("Personal Information") and
            health information. As part of this commitment, our privacy policy
            governs our actions whenever it relates to the collection, use and
            disclosure of your Personal Information and health information and
            our actions are, at all times, would be in strict compliance of the
            Personal Data Protection Act 2010 (“PDPA”). The following discloses
            our information gathering and dissemination practices.
            <br />
            <br />
            It is obligatory that you supply us with your personal data. If you
            fail to supply us with such personal data, we may not be able to
            process and/or disclose your data for the purposes as provided in
            Item (c) below.
          </p>
        </div>

        <!-- section A -->
        <div class="pb-2">
          <p>
            <b style="font-size: 1.2rem"
              >(a) Safeguarding Confidential Information</b
            >
          </p>
          <div class="card border-0">
            <div class="card-body">
              <p>
                These Information will be protected by security safeguards that
                are appropriate to the sensitivity level of the information. We
                take all reasonable precautions to protect these Information
                from any loss or unauthorized use, access or disclosure.
              </p>
            </div>
          </div>
        </div>

        <!-- section B -->
        <div class="pb-2">
          <p>
            <b style="font-size: 1.2rem"
              >(b) Limiting Use, Disclosure and Retention</b
            >
          </p>
          <div class="card border-0">
            <div class="card-body">
              The Information may only be used or disclosed for the purpose for
              which it was collected unless you have otherwise consented, or
              when it is required or permitted by law. The Information will only
              be retained for the period of time required to fulfil the purpose
              for which we collected it or as may be required by law.
            </div>
          </div>
        </div>

        <!-- section C -->
        <div class="pb-2">
          <p><b style="font-size: 1.2rem">(c) Personal Information</b></p>
          <div class="card border-0">
            <div class="card-header bg-white"><b>Definition</b></div>
            <div class="card-body">
              <p>
                Personal Information includes, but not limited to name, email
                address, personal contact number, payment information, medical
                records/history and any other personally identifiable
                information which such Personal Information data is collected
                from various sources, including information you have provided
                us, information from third parties and information in the public
                domain.
              </p>
            </div>
            <div class="card-header bg-white"><b>Identifying Purposes</b></div>
            <div class="card-body">
              <p>
                We collect, use and disclose Personal Information to provide you
                with the service. The purposes for which we collect Personal
                Information will be identified before or at the time we collect
                the information. Your personal data may be processed for the
                following purposes, where relevant: -
              </p>

              <div class="my-2">
                <ol>
                  <li>For medical and healthcare services</li>
                  <li>To facilitate the patients’ personal needs</li>
                  <li>
                    To establish and manage medical records and medical reports
                    (if any)
                  </li>
                  <li>
                    To facilitate payment process relating to the patients
                  </li>
                  <li>
                    To institute debt recovery proceedings against defaulters
                  </li>
                  <li>
                    To report the personal data to the relevant authorities
                    and/or third parties under the governing laws relevant to
                    the healthcare industry
                  </li>
                  <li>
                    To share the personal data with SELGATE Healthcare and its
                    affiliates as defined in the Companies Act 2016
                  </li>
                  <li>To conduct research, analysis and improvement</li>
                  <li>To market ad advertise products and services</li>
                  <li>
                    To administer and respond to request, queries, complaints
                    and legal issues
                  </li>
                  <li>
                    To facilitate human resource management activities relating
                    to employees
                  </li>
                  <li>
                    For submission and registration of relevant forms, licenses
                    to the relevant authorities and/or third parties under the
                    governing laws relevant to the healthcare industries
                  </li>
                  <li>
                    To share personal data for the purpose of banking
                    facilities, signing of legal and accounting
                  </li>
                  <li>For education and training</li>
                  <li>
                    For any other purposes that is incidental or in furtherance
                    to the above
                  </li>
                </ol>
              </div>
            </div>
            <div class="card-header bg-white">
              <b>Disclosure of Personal Data</b>
            </div>
            <div class="card-body">
              <p>
                Your personal data may be disclosed to the following parties, as
                follows: -
              </p>
              <div class="my-2">
                <ol>
                  <li>Healthcare professional (as defined in PDPA)</li>
                  <li>Government agencies, local authorities</li>
                  <li>Debt collection authorities and agencies</li>
                  <li>Financial institutions, Auditors</li>
                  <li>Other private and public hospitals</li>
                  <li>Family and next of kin</li>
                  <li>
                    Firm and such parties may be required by law, court,
                    regulator or legal process to disclose
                  </li>
                  <li>To such parties permitted under the law of Malaysia</li>
                  <li>
                    Any reasons and person which SELGATE and SELCARE may deem
                    necessary
                  </li>
                </ol>
              </div>
            </div>
            <div class="card-header bg-white">
              <b>Consent</b>
            </div>
            <div class="card-body">
              <p>
                Knowledge and consent are required for the collection, use or
                disclosure of Personal Information except where required or
                permitted by law. Providing us with your Personal Information is
                always your choice. However, if you decide to give your Personal
                Information to us voluntarily, at any time, in no case will we
                sell, license or transmit that information outside of
                Selcare.com for any reason, unless you authorise us to do so in
                strict compliance with this Privacy Policy and PDPA.
              </p>
            </div>
            <div class="card-header bg-white">
              <b>Limiting Collection</b>
            </div>
            <div class="card-body">
              <p>
                The Personal Information collected will be limited to those
                details necessary for the purposes identified by us. With your
                consent, we may collect Personal Information from you in person,
                over the telephone, via Selcare.com or by corresponding with you
                via mail.
              </p>
            </div>
            <div class="card-header bg-white">
              <b>Customer Access</b>
            </div>
            <div class="card-body">
              <p>
                Upon request, you will be informed of the existence, use and
                disclosure of your Information. You may verify the accuracy and
                completeness of your Personal Information, and may request that
                it be amended. We may also take steps to verify your identity
                before fulfilling your request for access to your personal data.
              </p>
            </div>

            <div class="card-header bg-white">
              <b>Health Information</b>
            </div>
            <div class="card-body">
              <p><b>Rights towards your health information</b></p>
              <ul>
                <li>
                  You have the right to receive confidential information about
                  your health status
                </li>
                <li>
                  You have the right to review and photocopy any/all portions of
                  your health information
                </li>
                <li>
                  You have the right to make changes to your health information
                </li>
                <li>
                  You have the right to know who has access your confidential
                  health information and for what purpose
                </li>
              </ul>
            </div>
            <div class="card-header bg-white">
              <b>Disclosure of your Health Information</b>
            </div>
            <div class="card-body">
              <p>
                Your confidential health information will not be released for
                any other purpose than that which is identified in this policy
                as specified above. We will not disclose your health information
                to unaffiliated third parties without first obtaining your
                written consent. You may revoke your permission to release your
                confidential health information at any time. However, SELGATE
                Healthcare may choose to refuse your restriction in the event of
                an emergency.
              </p>
            </div>
            <div class="card-header bg-white">
              <b>Handling Customer Complaints</b>
            </div>
            <div class="card-body">
              <p>
                You have the right to possess a copy of this Privacy Policy upon
                request. You may direct any complaints or enquiries to SELGATE
                Healthcare regarding this privacy policy or if you believe your
                rights to privacy have been violated.
              </p>
            </div>
          </div>
        </div>

        <!-- section A -->
        <div class="pb-2">
          <p>
            <b style="font-size: 1.2rem">(f) Contact Us</b>
          </p>
          <div class="card border-0">
            <div class="card-body">
              <p>
                Kindly contact us at the following modes shall you have any
                queries or complaints in respect of your personal data: -
              </p>
              <p>
                <b>Address:</b>
                <br />
                No. 1-02 & 1-03, Level 1, Jalan Pembangunan 14/6, Section 14,
                40000 Shah Alam, Selangor Darul Ehsan
              </p>
              <p>
                <b>Telephone Number:</b>
                <br />
                03-5521 7000
              </p>
              <p>
                <b>Email Address:</b>
                <br />
                <ins>admin.g@selgatecorporation.com</ins>
              </p>
              <p>
                By providing to us your personal data, you hereby consent to the
                processing of your personal data in accordance with all of the
                foregoing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "policy",
};
</script>

<style scoped>
p,
ol,
li {
  font-family: "Karla", serif !important;
}
</style>
